<!--
@Author: Calvin Green <calvin>
@Date:   2021-08-24T09:47:01+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-11-03T13:14:05+02:00
@Copyright: Diversitude 2021
-->



<script>
import appConfig from "@/app.config";

import { notificationMethods } from "@/state/helpers";

import store from '@/state/store'

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  data:()=>({
    timer: null,
  }),
  mounted() {
    this.refreshAuth()
    // document.querySelector("html").setAttribute('dir', 'rtl')
  },
  watch: {
    /**
     * Clear the alert message on route change
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // clear alert on location change
      this.clearNotification();
    },
  },
  methods: {
    clearNotification: notificationMethods.clear,

    refreshAuth(){
      this.timer = setInterval(() => {
        if (store.getters['auth/loggedIn']){
          store.dispatch('auth/refresh').then((validUser) => {
            if(validUser){
              console.log("Refreshed User");
            }
          })
        }
      }, 600000)//1200000
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
};
</script>

<template>
  <div id="app">
    <RouterView />
  </div>
</template>
