import axios from 'axios'
import _ from 'lodash'
const base = process.env.VUE_APP_API_BASEURL

export const state = {
    views: null,
    view_groups: null,
    selected_group_id: null,
    selected_view_id: null,
    selectedViewData:null,
    spreadsheets:[],

    reports:[],
    selected_report:{},

    edit_data:null,

    designer_mode: null,

}

export const actions = {
    getViewGroups({commit}, id){
        return new Promise((resolve, reject)=>{
            axios.get( base + 'api/clientapp/viewgroups/'+ id)
            .then((response)=>{
                /*var views = response.data;

                //* add random debug group
                var groups = ['First', 'Second', 'Third'];

                views.forEach((item)=>{
                    var group = groups[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
                    console.log(group)
                    item['group'] = group;
                });*/

                commit('setGroups', response.data);
                resolve();
            })
            .catch((e)=>{ 
                reject(e);
            })
        })
    },
    getViews({commit}, id){
        return new Promise((resolve, reject)=>{
            axios.get( base + 'api/clientapp/views/'+ id)
            .then((response)=>{
                commit('setViews', response.data);
                resolve(response.data);
            })
            .catch((e)=>{ 
                reject(e);
            })
        })
    },

    setSelectedView({commit},data){
        return new Promise((resolve)=>{
            if(data.id == -1){
                commit('resetSelectedView', null);
                resolve();
            }
            else{
                commit('updateSelectedView', data);
                resolve();
            }
        })
    },
    setSelectedGroupID({commit}, id){
        commit('setSelectedGroup', id);
    },
    //eslint-disable-next-line
    saveViewGroup({}, data){
        return new Promise((resolve, reject)=>{
            axios.post( base + 'api/clientapp/saveviewgroup', data)
            .then((response)=>{
                resolve(response.data);
            })
            .catch(()=>{ reject(); })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveView({}, data){
        return new Promise((resolve, reject)=>{
            axios.post( base + 'api/clientapp/saveview', data)
            .then((response)=>{
                resolve(response.data);
            })
            .catch(()=>{ reject(); })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    deleteViewGroup({}, id){
        return new Promise((resolve, reject)=>{
            axios.post( base + 'api/clientapp/deleteviewgroup', {group_id: id})
            .then(()=>{
                resolve();
            })
            .catch(()=>{ reject(); })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    deleteView({}, id){
        return new Promise((resolve, reject)=>{
            axios.post( base + 'api/clientapp/deleteview', {render_view_id: id})
            .then(()=>{
                resolve();
            })
            .catch(()=>{ reject(); })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    linkViewGroup({}, data){
        return new Promise((resolve, reject)=>{
            axios.post( base + 'api/clientapp/linkviewgroup', data)
            .then(()=>{
                resolve();
            })
            .catch(()=>{ reject(); })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    unlinkViewGroup({}, data){
        return new Promise((resolve, reject)=>{
            axios.post( base + 'api/clientapp/unlinkviewgroup', data)
            .then(()=>{
                resolve();
            })
            .catch(()=>{ reject(); })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveViewConfig({}, data){
        return new Promise((resolve, reject)=>{
            axios.post( base + 'api/clientapp/saveviewconfig', data)
            .then(()=>{
                resolve();
            })
            .catch(()=>{ reject(); })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveViewMapping({}, data){
        return new Promise((resolve, reject)=>{
            axios.post( base + 'api/clientapp/saveviewmapping', data)
            .then(()=>{
                resolve();
            })
            .catch((e)=>{ reject(e); })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    deleteViewMapping({}, id){
        return new Promise((resolve, reject)=>{
            axios.post( base + 'api/clientapp/deleteviewmapping', {mapping_id: id})
            .then(()=>{
                resolve();
            })
            .catch(()=>{ reject(); })
        })
    },

    loadUploadedSpreadsheets({commit}, id){
        return new Promise((resolve, reject)=>{
            axios.get( base + 'api/clientapp/spreadsheets/' + id)
            .then((response)=>{
                commit('setSpreadsheets', response.data);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },

    //eslint-disable-next-line no-empty-pattern
    generateApiKey({}, app_id){
        return new Promise((resolve, reject)=>{
            axios.post(base + 'api/clientapp/generateapikey',{client_app_id: app_id})
            .then(response =>{
                resolve(response.data);
            })
            .catch(error =>{
                console.log(error)
                reject(error);
            })
        })
    },


    //* Reports
    getReports({commit}, appID){
        return new Promise((resolve, reject)=>{
            axios.get( base + 'api/clientapp/reports/'+ appID)
            .then((response)=>{
                commit('setReports', response.data);
                resolve();
            })
            .catch((e)=>{ 
                reject(e);
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveReportPage({}, page){
        return new Promise((resolve, reject)=>{
            axios.post( base + 'api/clientapp/savereportpage', page)
            .then((response)=>{
                resolve(response);
            })
            .catch((e)=>{ 
                reject(e);
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveReport({}, data){
        return new Promise((resolve, reject)=>{
            axios.post( base + 'api/clientapp/savereport', data)
            .then((response)=>{
                resolve(response.data.id);
            })
            .catch((e)=>{ 
                reject(e);
            })
        })
    },
    selectReport({commit}, report){
        return new Promise((resolve)=>{
            commit('setSelectedReport', report);
            resolve();
        })
    },

    //* Misc
    loadEditData({commit}, data){
        return new Promise(resolve=>{
            commit('setEditData', data);
            resolve();
        })
    },
    //eslint-disable-next-line no-empty-pattern
    addSheet({}, data){
        return new Promise(resolve=>{
            axios.post( base + 'api/clientapp/addsheet', data)
            resolve();
        })
    },

    setDesignerMode({commit}, mode){
        commit('setDesignerMode', mode);
    }
}

export const mutations = {
    //* VIEWS ----------
    setViews(state, payload){
        state.views = payload;
    },
    setGroups(state, payload){
        state.view_groups = payload;
    },
    setSelectedGroup(state, payload){
        state.selected_group_id = payload;
    },
    updateSelectedView(state, data){
        var group_id = data.group;
        var view_id = data.id;
        var g_index = _.findIndex(state.view_groups, {id:group_id});
        if(g_index != -1){
            var v_index = _.findIndex(state.view_groups[g_index].views, {id: view_id});
            if(v_index != -1){
                state.selected_view_id = view_id;
                var conf = _.cloneDeep(state.view_groups[g_index].views[v_index]);
                var conf_parsed = JSON.parse(conf.config_json);
                conf['config_json'] = conf_parsed;
                state.selectedViewData = conf;
            }
        }
    },
    resetSelectedView(state, id){
        state.selected_view_id = id;
        state.selectedViewData = null;
    },
    setSpreadsheets(state, payload){
        state.spreadsheets = payload;
    },
    //* ---------

    //* REPORTS ---------
    setReports(state, payload){
        state.reports = payload;
    },
    setSelectedReport(state, payload){
        state.selected_report = payload;
    },
    setEditData(state, payload){
        state.edit_data = payload;
    },
    setDesignerMode(state, payload){
        state.designer_mode = payload;
    }
}