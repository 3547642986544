import { $$ } from './webix-pro'
import * as webix_plugin from "./webix-pro/webix.js"
import './webix-pro/webix.js'

export default {
    //eslint-disable-next-line no-unused-vars
    install(Vue, options){
        //*Init webix datatable
        window.webix_plugin = webix_plugin;
        function plugin_data_handler(value){
            var view = $$(this.webixId);
            if (typeof value === "object"){
                if (this.copyData)
                    value = webix_plugin.copy(value);
                if (view.setValues)
                    view.setValues(value);
                else if (view.parse){
                    view.clearAll();
                    view.parse(value)
                }
            } else if (view.setValue)
                view.setValue(value);
            var subs = view.queryView(function(sub) {
                return sub.hasEvent && sub.hasEvent("onValue");
            }, "all");
            if (subs.length) {
                for (var i = 0; i < subs.length; i++) {
                    subs[i].callEvent("onValue", [value]);
                }
            }
        }

        Vue.component('webix-table-display', {
            props: ['config', 'value', 'copyData'],
            watch:{
                value:{
                    handler:plugin_data_handler
                }
            },
            template:"<div></div>",
            mounted:function(){
                var config = webix_plugin.copy(this.config);
                config.$scope = this;
            
                this.webixId = webix_plugin.ui(config, this.$el);
                if (this.value)
                    plugin_data_handler.call(this, this.value);
            },
            destroyed:function(){
                webix_plugin.$$(this.webixId).destructor();
            }
        })
        //Add mixin
        Vue.mixin({
            created(){
                
            },
        })

        //* Define the element used to display the render view
        Vue.component('spreadify-plugin', {
            data:()=>({
                finishedLoading: false,
                config_data:{}
            }),
            template:
            `
                <div>
                    <h2>{{this.name}}</h2>
                    <h4>{{this.description}}</h4>
                    <webix-table-display v-if="finishedLoading" :config="config" />
                </div>
            `,
            watch:{
                finishedLoading(newVal){
                    if(newVal){
                        this.$nextTick(()=>{
                            //eslint-disable-next-line no-undef
                            var table = $$('tableBuilder');

                            //*Loop through mapped data and apply formatting based on data_type variable
                            this.data.forEach( item => {//Currency, Decimal, Percentage,
                                //todo -- remove temp data_type
                                item['data_type'] = 'currency';
                                var [row, column] = item.mapped_cell_id.split('|');
                                switch (item.data_type){
                                    case 'percentage':
                                        item.value = (Number(item.value)*100)+ '%';
                                        break;
                                    case 'currency':
                                        item.value = webix_plugin.i18n.priceFormat(item.value);
                                        break;
                                    case 'decimal':
                                        item.value = webix_plugin.i18n.numberFormat(item.value)
                                        break;
                                    default:
                                        break;
                                }
                                table.updateItem(row, {[column]: item.value})
                            })
                            table.refresh();
                        });
                    }
                }
            },
            mounted(){
                //*Import locale and then set selected locale
                import(`../locales/webix/${this.locale}`).then(()=>{
                    webix_plugin.i18n.setLocale(this.locale);
                    this.finishedLoading = true;
                })
                this.config_data = JSON.parse(JSON.stringify(this.config))
                this.config_data.id = 'tableBuilder'+ this.key;
            },
            props: {// plugin props
                config:{
                    type: Object,
                    required: true,
                },
                data:{
                    type: Array,
                    require: true,
                },
                name:{
                    type: String,
                    require: false,
                },
                description:{
                    type: String,
                    require: false,
                },
                locale:{
                    type: String,
                    required: false,
                    default: function(){
                        return 'en-ZA'
                    }
                },
            },
        })
    }
}