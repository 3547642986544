
export const state = {
    isPainterModeEnabled: null,
    painterStyles: '',
    hasMadeChanges: false,
    
};

export const actions = {
    togglePainterMode({ commit }, enabled) {
        commit('setPainterMode', enabled);
    },
    savePainterStyles({commit}, styles){
        commit('setPainterStyles', styles);
    },
    setMadeChangesFlag({commit}, value){
        commit('setMadeChanges', value);
    }
};

export const mutations = {
    setPainterMode(state, payload) {
        state.isPainterModeEnabled = payload
    },
    setPainterStyles(state, payload) {
        state.painterStyles = payload
    },
    setMadeChanges(state, payload){
        state.hasMadeChanges = payload;
    }
};


