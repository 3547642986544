import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader
  })
}

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'register', 'resetPassword'])

export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', 'changeLoaderValue'])

export const authFackMethods = mapActions('authfack', ['login', 'registeruser', 'logout'])

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear'])

export const clientMethods = mapActions('client', ['getApps', 'saveNewApp', 'setSelectedID', 'setSelectedApp'])
export const clientComputed = {
  ...mapState('client', {
    apps: (state) => state.apps,
    selected_app_id: (state) => state.selected_app_id,
    selected_app: (state) => state.selected_app,
  })
}

export const clientViewMethods = mapActions('clientApp', 
['getViews', 'addSheet', 'getViewGroups', 'saveViewGroup', 'saveView', 'deleteView', 'setSelectedView', 'saveViewConfig', 
'unlinkViewGroup', 'linkViewGroup', 'deleteViewGroup',
'saveViewMapping', 'deleteViewMapping', 'loadUploadedSpreadsheets', 'setSelectedGroupID',
'generateApiKey', 'getReports', 'saveReport', 'saveReportPage' ,'selectReport', 'loadEditData', 'setDesignerMode'])
export const clientViewComputed = {
  ...mapState('clientApp', {
    views: (state) => state.views,
    view_groups: (state) => state.view_groups,
    selected_group_id: (state) => state.selected_group_id,
    selected_view_id: (state) => state.selected_view_id,
    selectedViewData: (state) => state.selectedViewData,
    spreadsheets: (state) => state.spreadsheets,
    reports: (state) => state.reports,
    selected_report: (state) => state.selected_report,
    edit_data: (state) => state.edit_data,
    designer_mode: (state) => state.designer_mode,
  })
}

export const builderMethods = mapActions('builder', ['togglePainterMode', 'savePainterStyles', 'setMadeChangesFlag'])
export const builderComputed = {
  ...mapState('builder', {
    isPainterModeEnabled: (state) => state.isPainterModeEnabled,
    painterStyles: (state) => state.painterStyles,
    hasMadeChanges: (state) => state.hasMadeChanges,
  })
}

export const builderFormMethods = mapActions('builderForm', ['editElement', 'loadGeneratedFormData', 'loadAvailableGroups', 'setClipboardData']);
export const builderFormComputed = {
  ...mapState( 'builderForm', {
    editData: (state) => state.editData,
    generatedFormData: (state) => state.generatedFormData,
    availableGroups: (state) => state.availableGroups,
    clipboard: (state) => state.clipboard,
  })
}

export const todoComputed = {
  ...mapState('todo', {
    todos: (state) => state.todos
  })
}



export const todoMethods = mapActions('todo', ['fetchTodos'])