
export const state = {
    editData:{},
    generatedFormData:[],
    availableGroups:[],
    current_form_data:[],
    clipboard: null,
};

export const actions = {
    editElement({commit}, data){
        return new Promise((resolve)=>{
            commit('setEditData', data);
            resolve();
        })
    },
    loadGeneratedFormData({commit}, data){
        return new Promise((resolve)=>{
            commit('setGenerateFormData', data);
            resolve();
        })
    },
    loadAvailableGroups({commit}, data){
        return new Promise((resolve)=>{
            commit('setAvailableGroups', data);
            resolve();
        })
    },
    updateCurrentForm({commit}, data){
        return new Promise((resolve)=>{
            commit('setCurrentFormData', data);
            resolve();
        })
    },
    setClipboardData({commit}, data){
        return new Promise((resolve)=>{
            commit('setClipboardData', data);
            resolve();
        })
    }
};

export const mutations = {
    setEditData(state, payload){
        state.editData = payload;
    },
    setGenerateFormData(state, payload){
        state.generatedFormData = payload;
    },
    setAvailableGroups(state, payload){
        state.availableGroups = payload;
    },
    setCurrentFormData(state, payload){
        state.current_form_data = payload;
    },
    setClipboardData(state, payload){
        state.clipboard = payload;
    },
};


