import _ from 'lodash';
import {getSpreadifyBackend} from '@/helpers/spreadify.js';
import './uploader.css';
import './form.css';

//import { ValidationProvider } from 'vee-validate';
import { required } from 'vuelidate/lib/validators';


export default {
    //eslint-disable-next-line no-unused-vars
    install(Vue, options) {

        Vue.mixin({created() {},});

        //* Form Elements
        
        Vue.component('f-text', {
            data:()=>({
                element_data:'',
                errors:[],
            }),
            props:{
                item:{
                    required:true,
                },
                data:{
                    default: null,
                },
                admin:{
                    default: false,
                }
            },
            watch:{
                element_data(){
                    this.$emit('updateVariable', {key: this.item.name, value: this.element_data})
                }
            },
            validations(){
                return{
                    element_data:{
                        required,
                    }
                }
            },
            mounted(){
                if(this.data != null){
                    this.element_data = _.cloneDeep(this.data);
                }
            }, 
            template:
            `<div>
                <b-form-input :readonly="admin" class="form__input" v-model.trim="$v.element_data.$model"></b-form-input>
                <div class="error" v-if="!$v.element_data.required">Field is required</div>
            </div>`
        })


        Vue.component('f-select', {
            data:()=>({
                element_data: null,
                selected: null,
            }),
            props:{
                data:{
                    default: null,
                },
                options:{
                    default:[],
                },
                variable:{
                    required: true,
                },
                admin:{
                    default: false,
                },
                item:{},
                
            },
            watch:{
                element_data(){
                    this.$emit('updateVariable', {key: this.item.name, value: this.element_data})
                }
            },
            mounted(){
                if(this.data){
                    this.element_data = _.cloneDeep(this.data);
                }
            },
            template:
            `
            <div>
                <b-form-select v-model="element_data" class="custom-form-control" :disabled="admin" :options="options">
                    <template #first>
                        <b-form-select-option :value="null">-- Please select an option --</b-form-select-option>
                    </template>
                </b-form-select>
            </div>
            `
        })
        Vue.component('f-uploader', {
            data:()=>({
                attrs: {
                    accept: 'image/*'
                },
                file: null,
                uploaded: 0,
                progress: 0,
                collapse_state: false,
            }),
            mounted(){
                this.$eventHub.$on('uploaderProgress', this.uploaderProgress);
            },
            beforeDestroy(){
                this.$eventHub.$off('uploaderProgress');
            },
            props:{
                admin:{},
                accept:{
                    default:''
                },
                viewFile:{},
                item:{},
                itemID:{},
            },
            computed:{
                isVersion2(){
                    return window.form_inst.version2;
                },
                lastFile(){
                    let index = this.viewFile.length - 1;
                    return this.viewFile[index];
                },
                files(){
                    let files = window.form_inst.viewFile[this.itemID];
                    if(files){
                        return files;
                    }
                    return []
                },
            },
            methods:{
                select(event) {
                    if(event.target.files.length == 1){
                        //* One file added to component
                        this.file = event.target.files.item(0);
                    }
                    else{
                        //* Multiple files added to component
                        this.file = event.target.files;
                    }
                    

                    this.upload();
                },
                upload() {
                    let payload = {
                        file: this.file,
                        form_key: this.itemID,
                    }
                    this.$emit('uploaderData', payload);

                },
                downloadFile(id = null){
                    if(id == null){
                        let params = {
                            item_type: 'client_upload',
                            item_id: this.lastFile.id,
                        }
                        this.$emit('downloadFile', params)
                    }
                    else{
                        this.$emit('downloadFile', id)
                    }
                    
                },
                deleteFile(evidence_id){
                    alert(evidence_id)
                    this.$emit('deleteFile', evidence_id);
                },

                uploaderProgress(payload){
                    let progress = null;
                    //new forms have a payload as an object
                    if(payload.progress != undefined){
                        // check if the id of the payload matches the current element
                        if(payload.form_key == this.item.name){
                            progress = payload.progress
                        }
                    }
                    else{
                        progress = payload;
                    }
                    //alert(progress)
                    if(Number(progress) > this.progress){
                        this.progress = Number(progress);
                    }
                }
            },
            template:
            `
            <div>
            <span @click="collapse_state = !collapse_state" style="cursor: pointer;">
                <span v-if="collapse_state">
                    <i class="fas fa-caret-up me-2"/>Collapse
                </span>
                <span v-if="!collapse_state">
                    <i class="fas fa-caret-down me-2"/>Expand
                </span>
            </span>
            <b-collapse v-model="collapse_state" >
                <div class="d-flex">
                    <div v-if="isVersion2 == false">
                    <!-- OLD VERSION USED FOR THE PRE-INSPECTION STEPS -->
                        <div v-if="viewFile != [] && lastFile != undefined">
                            <b-row>
                                <b-col class="mb-2" cols="12"><strong>Name: </strong>{{lastFile.filename}}</b-col>
                                <b-col class="mb-2" cols="12"><strong>Uploaded By: </strong>{{lastFile.upload_user_name}}</b-col>
                                <b-col class="mb-2" cols="12"><strong>Date: </strong>{{lastFile.created_at}}</b-col>
                                <b-col class="mb-2" cols="12">
                                    <b-button @click="downloadFile()" variant="info">Create Download Link</b-button>
                                </b-col>
                            </b-row>
                        </div>
                        <!--<div v-else>
                            <p>No file uploaded </p>
                        </div>-->
                    </div>

                    <div v-show="!admin" class="bvUploader" :class="files.length == 0 ? 'w-100' : 'w-50' ">
                        <label>Upload</label>
                        <input multiple type="file" @change="select">
                    </div>
                    <progress style="width: 100%;" v-if="false && progress > 0" :value="progress" max="100" />

                    <div v-if="isVersion2 && files.length > 0" style="height: 200px; overflow: auto; border: 2px dashed lightsteelblue; border-left: 0px;" class="w-50">
                        <label class="w-100">Download</label>
                        <div style="display: flex; flex-direction: column; align-items: stretch;">
                            <div v-for="file in files" :key="file.id" class="d-flex border border-black" style="min-height: 4rem; max-height: 4rem;">
                                <span class="text-truncate">{{file.evidence_name}}</span>
                                <span class=" ms-auto mt-auto">
                                    <b-button variant="outline-primary" size="sm" @click="">
                                        <i class="fas fa-cloud-download-alt"/>
                                    </b-button>
                                    <b-button variant="outline-danger" size="sm" @click="deleteFile(file.id)">
                                        <i class="fas fa-trash"/>
                                    </b-button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </b-collapse>
            </div>
            `
        })

        Vue.component('f-check', {
            data:()=>({
                element_data:[],
                errors:[],
            }),
            props:{
                options:{
                    default:[],
                },
                variable:{
                    required: true,
                },
                step:{
                    default: 0,
                },
                data:{
                    default: null,
                },
                admin:{},
            },
            watch:{
                element_data(){
                    let str = this.element_data.join('|');
                    this.$emit('updateVariable', {key: this.variable, value: str})
                }
            },
            mounted(){
                if(this.data != null){
                    let arr = this.data;
                    this.element_data = arr.split('|');
                }
                
            }, 
            template:
            `
                    <b-form-checkbox-group :disabled="admin" v-model="element_data" :options="options"></b-form-checkbox-group>
                    <span v-if="errors.length > 0">{{errors}}</span>
            `
        })


        //* Main Form Component
        Vue.component('form-plugin', {
            data: () => ({
                hasMounted: false,
                formData:{},
                elements:[],
                pager_config:{
                    current_page: 1,
                    length: 0
                },
                collapse_id: null,
                table_of_contents_data:[],
                table_config:{
                    id: 'contentsTable',
                    view: 'datatable',
                    css: "datatableUsers webix_header_border webix_data_border",
                    autoheight: true,
                    //hover: "datatableHover",
                    tooltip: true,
                    minHeight:50,
                    scrollY:false,
                    scrollX:false,
                    resizeColumn: true,
                    select: true,
                    pager:{
                        template:"{common.prev()} {common.pages()} {common.next()}",
                        container:"contentsPaging",
                        size:5,
                        group:7
                    },
                    ready(){
                        setTimeout(()=>{
                            let first_id = this.getFirstId();
                            if(this.exists(first_id)){
                                this.select(first_id);
                            }
                        })
                    },
                    columns:[
                        { id: 'id', header: ['', ''], fillspace: 0.15},
                        { id: 'name', header: ['Table of Contents', {content: "textFilter"}], tooltip: "#name#", fillspace: 1},
                        { id: 'failed', header: ["<div class='centro' title='Questions Failed'><i class='text-danger fas fa-times'></i></div>", ''], fillspace: 0.2},
                        { id: 'unanswered', header: ["<div class='centro' title='Questions Unanswered'><i class=' text-warning fas fa-exclamation-triangle'></i></div>", ''], fillspace: 0.2},
                    ],
                    on:{
                        onAfterSelect(id){
                            //let el = this.$scope.$parent.$parent.$parent;
                            let item = this.getItem(id);
                            let pager = window.form_inst.pager_config
                            if(pager.current_page != Number(item.index)+1){
                                pager.current_page = Number(item.index)+1;
                            }
                            
                        }
                    }
                }
            }),
            template: `
            <div blur="0" variant="transparent" :show="hasMounted == false" >
                <div v-if="!hasMounted">
                    <h2 class="p-4 m-4">Loading Form</h2>
                </div>
                <b-row v-if="hasMounted">
                    <b-col :cols="formColumnWidth">              
                        <b-form class="formPlugin" ref="formPlugin" @submit.prevent >
                            <b-form-group v-for="(group, index) in elementsComputed" :key="group.id">
                                <div v-if="group.elements.length > 0">
                                    <hr v-if="index > 0">
                                    <h4>{{group.name}}</h4>
                                    <b-form-row>
                                            <b-col class="input-col" :cols="item.options.cols" v-for="item in group.elements" :key="item.id">
                                                <label class="input-label" for="">
                                                    {{item.display_name}}
                                                    <i @click="showSelectedHint(item)" :title="item.options.popup_text" v-show="item.options.popup_text" :style="item.id == collapse_id ? 'color: dodgerblue;': '' " style="cursor: pointer;" class="fas fa-question-circle"/>
                                                </label>
                                                <b-collapse v-model="item.id == collapse_id" >
                                                    <p style="font-size: 10px; margin-bottom: 0;">{{item.options.popup_text}}</p>
                                                </b-collapse>
                                                <div v-if="item.type=='textfield'">
                                                    <f-text :admin="admin" @updateVariable="updateVariable" :item="item" :data="formData[item.name]"></f-text>
                                                    <!--<component :is="returnComponentType(item)" :type="item.options.type" :placeholder="item.options.placeholder" :name="item.name" :value="item.options.value" v-model="formData[item.name]"/> -->
                                                </div>
                                                <div v-else-if="item.type=='textarea'">
                                                    <component :readonly="admin" :is="returnComponentType(item)" :name="item.name" :placeholder="item.options.placeholder" :rows="item.options.rows" :max-rows="item.options.max_rows" :no-resize="item.options.no_resize" v-model="formData[item.name]" />
                                                </div>
                                                <div v-else-if="item.type=='select'">
                                                    <f-select :admin="admin" @updateVariable="updateVariable" :item="item" :options="item.option_values" :variable="item.name" :data="formData[item.name]" > </f-select>
                                                </div>
                                                <div v-else-if="item.type=='check'">
                                                    <f-check :admin="admin" @updateVariable="updateVariable" :options="item.option_values" :data="formData[item.name]" :variable="item.name"></f-check>
                                                </div>
                                                <div v-else-if="item.type=='selectz'">
                                                    <component :is="returnComponentType(item)" :options="item.option_values" v-model="formData[item.name]" />
                                                </div>
                                                <div v-else-if="item.type=='datepicker'">
                                                    <component :readonly="admin" :is="returnComponentType(item)" :name="item.name" :min="item.options.min_date" :max="item.options.max_date" :placeholder="item.options.placeholder" :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }" v-model="formData[item.name]" />
                                                </div>
                                                <div v-else-if="item.type=='radio'">
                                                    <component :disabled="admin" :is="returnComponentType(item)" :options="item.option_values" v-model="formData[item.name]" />
                                                </div>
                                                <div v-else-if="item.type=='timepicker'">
                                                    <component :readonly="admin" :is="returnComponentType(item)" :placeholder="item.options.placeholder" :value="item.options.default_value" v-model="formData[item.name]" />
                                                </div>

                                                <div v-else-if="item.type=='html'">
                                                    <div v-html="item.options.default_value"/>
                                                </div>

                                                <div v-else-if="item.type=='uploader'">
                                                    <f-uploader :admin="admin" :viewFile="viewFile" @deleteFile="emitDeleteToParent" @downloadFile="returnDownloadID" @uploaderData="handleUploadData" :accept="item.options.file_type" :item="item" :itemID="item.name"></f-uploader>
                                                </div>
                                            </b-col>
                                        
                                    </b-form-row>
                                </div>
                                <b-pagination
                                    style="justify-content: center;"
                                    v-model="pager_config.current_page"
                                    :total-rows="pager_config.length"
                                    :per-page=1
                                    first-text="First"
                                    prev-text="Prev"
                                    next-text="Next"
                                    last-text="Last"
                                ></b-pagination>
                            </b-form-group>
                        </b-form>
                    </b-col>

                    <b-col v-if="enable_contents_table" cols="5">
                        <webix-ui :config="table_config" id="contentsTable" v-model="computed_table_contents" />
                        <div id="contentsPaging"></div>
                        <div class="d-flex" style="margin-top: 10px;" v-if="activeAudit">
                            <b-button
                                class="me-2 mb-2"
                                variant="primary"
                                v-show="enableSaveButton"
                                @click="saveAuditSession"
                            >Save Audit Progress</b-button>
                            <b-button
                                class="me-2 mb-2"
                                variant="danger"
                                v-show="enableSaveButton"
                                @click="$emit('endAudit')"
                            >End Audit Session</b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>
            `,
            watch:{
                uid(){
                    this.loadFormData();
                },
                viewData:{
                    deep: true,
                    handler(){
                        if(this.hasMounted && this.local_data){
                            /*console.log('View Data', this.viewData)
                            this.formData = {};
                            this.viewData.forEach((item => {
                                console.log('UPDATED FORM DATE',item)
                                this.formData[item.key] = item.value;
                            }))
                            this.$nextTick(()=>{
                                    
                            })*/
                            this.formData = {};
                            this.processFormData()
                            
                        }
                    }
                },
                formData:{
                    deep: true,
                    handler(){
                        //* Emit form data to parent element
                        let payload = {
                            data: this.formData,
                            uid: this.uid,
                        }
                        this.$emit('formDataUpdated', payload);
                    }
                },
                hasMounted(){
                    if(this.enable_contents_table){
                        this.$nextTick(()=>{
                            let table = window.webix.$$('contentsTable');
                            if(table){
                                table.select(table.getFirstId());
                            }
                            
                        })
                    }
                },
                'pager_config.current_page'(newValue){
                    let table = window.webix.$$('contentsTable');
                    let table_index = table.getSelectedItem().index;
                    if(Number(table_index) != newValue - 1){
                        let pager_position = newValue / this.table_config.pager.size;
                        let remainder = newValue % this.table_config.pager.size;
                        pager_position = ~~pager_position;
                        if(remainder == 0){
                            // if remainder == 0 then fix the off by 1 side effect (eg item 5 is still part of group 1)
                            pager_position--;
                        }

                        if(Number(table.getPage()) != pager_position){
                            table.setPage(pager_position)
                        }
                        table.select(newValue);
                    }
                }
            },
            mounted() {
                window.form_inst = this;
                //* Load config for uid
                //if(this.local_data == false){
                    this.loadFormData();
                //}
                
            },
            beforeDestroy(){
                window.form_inst = undefined;
            },
            props: {
                enable_contents_table:{
                    type: Boolean,
                    defualt: false,
                },
                admin:{
                    default: function(){
                        return false;
                    }
                },
                viewFile:{
                    default: function(){
                        return {};
                    }
                },
                viewData:{
                    default: function(){
                        return [];
                    }
                },
                uid:{ required: true},
                enableSaveButton:{
                    type: Boolean,
                    required: false,
                    default(){
                        return false;
                    }
                },
                activeAudit:{
                    type: Boolean,
                    required: false,
                    default(){
                        return false;
                    }
                },
                local_data:{
                    type: Boolean,
                    required: false,
                    default(){
                        return false;
                    }
                },
                version2:{
                    type: Boolean,
                    required: false,
                    default(){
                        return false;
                    }
                },
                buttonText: { default: 'Save' },
                buttonType: { default: 'success' }
            },
            //components:{
            //    ValidationProvider
            //},
            computed:{
                formColumnWidth(){
                    // set column to width of 8 if table is enabled, else set col to full width
                    return this.enable_contents_table ? 7 : 12;
                },
                complianceData() {
                    let data = this.table_of_contents_data;
                    let nonCompliant = 0;
                    let compliant = 0;
                    let totalQuestions = 0;
                    data.forEach( item => {
                        let elements = this.elements[item.index].elements;
                        elements.forEach( element => {
                            if( element.type != 'html' && (this.formData[element.name] == null || this.formData[element.name] == undefined)){
                                nonCompliant++;
                            }
                            else if(this.formData[element.name] == 'false'){
                                nonCompliant++;
                            } else {
                                compliant++;
                            }
                            totalQuestions++;
                        })
                    });
                    let compliance = {
                        compliant: (nonCompliant == 0 ? true : false),
                        compliance_percentage: Math.round((compliant / totalQuestions) * 100)
                    };
                    return compliance;
                },
                computed_table_contents(){
                    let data = this.table_of_contents_data;
                    data.forEach( item => {
                        let elements = this.elements[item.index].elements;
                        let failed = 0;
                        let unanswered = 0;
                        elements.forEach( element => {
                            if( element.type != 'html' && (this.formData[element.name] == null || this.formData[element.name] == undefined)){
                                unanswered++;
                            }
                            else if(this.formData[element.name] == 'false'){
                                failed++;
                            }
                        })

                        item['failed'] = failed == 0 ? 0 : `<strong class="text-danger">${failed}</strong>`
                        item['unanswered'] = unanswered == 0 ? 0 : `<strong class="text-warning">${unanswered}</strong>`
                    });

                    return data;
                },
                elementsComputed(){
                    //eslint-disable-next-line
                    var shown_elements = [];
                    if(this.hasMounted == false ){
                        return []
                    }   
                    else{
                        shown_elements = _.cloneDeep(this.elements);
                        shown_elements.forEach((group) => {
                            group.elements.forEach((element, index)=>{
                                if(element.options.conditional_value == false){
                                    //console.log('---- no condition');
                                }
                                else{
                                    if(this.formData[element.options.conditional_text] != undefined && element.options.conditional_value != this.formData[element.options.conditional_text]){
                                        group.elements.splice(index, 1);
                                    }
                                    else if(this.formData[element.options.conditional_text] == undefined){
                                        group.elements.splice(index, 1);
                                    }
                                }
                            })
                        })
                        return [shown_elements[this.pager_config.current_page -1]]
                    }
                    //return shown_elements;
                    
                }
            },
            methods:{
                logMe(){
                    console.table(this.formData);
                },
                loadFormData(){
                    if(this.uid != null){
                        this.hasMounted = false;
                        getSpreadifyBackend().getViewConfig(this.uid)
                        .then((response) => {
                            this.config = response;
                            this.config.config_json = JSON.parse(this.config.config_json);
                            
                            this.formData = {};
                            this.elements = this.config.config_json;
                            
                            if(this.enable_contents_table){
                                this.configureContentsTable();
                            }

                            this.processFormData();
                            
                            
                            this.pager_config.length = this.elements.length;

                            this.hasMounted = true;

                            this.$emit('hasLoaded');
                        })
                        .catch((error) => { console.log(error)});
                    }
                },
                processFormData(){
                    if(this.local_data){
                        console.log('DEBUG', this.viewData);
                        this.viewData.forEach((item => {
                            //* payload uses form_key
                            this.formData[item.form_key] = item.value;
                        }))
                    }
                    else{
                        this.viewData.forEach((item => {
                            this.formData[item.key] = item.value;
                        }))
                    }
                },
                configureContentsTable(){
                    this.elements.forEach( (group, index) => {
                        this.table_of_contents_data.push({
                            id: index + 1,
                            index: index,
                            name: group.name,
                        });
                    });
                },
                saveAuditSession() {
                    let saveParams = {
                        form_data: this.formData,
                        compliance_data: this.complianceData
                    };
                    this.$emit('savedEvent', saveParams);
                },
                updateVariable(data){
                    let new_formData = _.cloneDeep(this.formData);
                    //*Handle variable update event (used for new type elements)
                    new_formData[data.key] = data.value;//JSON.stringify(data.value);
                    this.formData = _.cloneDeep(new_formData);
                    
                    //this.formData[data.key] = data.value;//JSON.stringify(data.value);
                },

                handleUploadData(payload){
                    if(this.version2){
                        //emit as object with form_key and file
                        this.$emit('uploaderData', payload);
                    }
                    else{
                        //emit only file data
                        this.$emit('uploaderData', payload.file);
                    }
                },
                
                returnComponentType(item){
                    if(item.type == 'textfield'){
                        return 'b-form-input'
                    }
                    else if(item.type == 'select'){
                        return 'b-form-select'
                    }
                    else if(item.type == 'textarea'){
                        return 'b-form-textarea'
                    }
                    else if(item.type == 'datepicker'){
                        return 'b-form-datepicker'
                    }
                    else if(item.type == 'radio'){
                        return 'b-form-radio-group'
                    }
                    else if(item.type == 'timepicker'){
                        return 'b-form-timepicker'
                    }
                },

                returnDownloadID(params){
                    //*Emit file id to parent
                    this.$emit('downloadFile', params);
                },
                emitDeleteToParent(id){
                    this.$emit('deleteFile', id);
                },

                showSelectedHint(item){
                    this.collapse_id = item.id == this.collapse_id ? null : item.id;
                }
            }
        })
    }
}
