/**
 * @Author: Calvin Green <calvin>
 * @Date:   2021-02-27T19:26:51+02:00
 * @Email:  calvin@diversitude.com
 * @Filename: main.js
 * @Last modified by:   calvin
 * @Last modified time: 2021-05-17T10:13:46+02:00
 * @Copyright: Calvin Green 2020
 */


import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons, BadgePlugin, DropdownPlugin, FormTagsPlugin } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import VueRouter from 'vue-router'
import vco from "v-click-outside"
import router from './router/index'
import Scrollspy from 'vue2-scrollspy';
import VueSweetalert2 from 'vue-sweetalert2';
import uploader from 'vue-simple-uploader'

import * as webix from "./plugin/webix-pro/webix.js"
import './plugin/webix-pro/webix.css'

import "../src/design/app.scss";

import store from '@/state/store'

import App from './App'

//import { initFirebaseBackend } from './authUtils'
import i18n from './i18n'

import _ from 'lodash'

import SpreadifyRenderPlugin from "./plugin/spreadifyRenderPlugin.js"

import './design/css/custom.css'
import './design/css/custom_webix.css'

import draggable from 'vuedraggable'

window.webix = webix;
window.axios = require("axios");
window.axios.defaults.headers.common = {
    'Access-Control-Allow-Origin': '*',
    'X-Requested-With': 'XMLHttpRequest'
};
window.axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL;
window.axios.defaults.withCredentials = true;
window.axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    var token = localStorage.getItem('x_token');
    if (token) {
        const AUTH_TOKEN = token;
        config.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

import FormPlugin from './plugin/form-plugin/form-plugin.js'
Vue.use(FormPlugin)
Vue.use(VueRouter)
Vue.use(vco)
Vue.use(Scrollspy);
const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)
Vue.config.productionTip = false

Vue.use(_);

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(FormTagsPlugin)
Vue.use(BadgePlugin)
Vue.use(DropdownPlugin)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueSweetalert2);
Vue.component('apexchart', VueApexCharts);
Vue.use(uploader)

Vue.use(draggable);

Vue.use(SpreadifyRenderPlugin);

Vue.prototype.$eventHub = new Vue(); // Global event bus

import {initSpreadifyBackend} from '@/helpers/spreadify';
initSpreadifyBackend('21|Fb3I7n53xctfgDsFIt9i06ysTfwfY33TiFr5zxtH');

function data_handler(value){
  //eslint-disable-next-line no-undef
  var view = $$(this.webixId);

  if (typeof value === "object"){
    if (this.copyData)
      value = webix.copy(value);

    if (view.setValues)
      view.setValues(value);
    else if (view.parse){
      view.clearAll();
      view.parse(value)
    }
  } else if (view.setValue)
    view.setValue(value);

  var subs = view.queryView(function(sub) {
    return sub.hasEvent && sub.hasEvent("onValue");
  }, "all");

  if (subs.length) {
    for (var i = 0; i < subs.length; i++) {
      subs[i].callEvent("onValue", [value]);
    }
  }
}

Vue.component("webix-ui", {
  props: ['config', 'value', 'copyData'],
  watch:{
    value:{
      handler:data_handler
    }
  },

  template:"<div></div>",

  mounted:function(){
    var config = webix.copy(this.config);
    config.$scope = this;

    this.webixId = webix.ui(config, this.$el);
    if (this.value)
      data_handler.call(this, this.value);
  },
  destroyed:function(){
    webix.$$(this.webixId).destructor();
  }
});



new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
