/**
 * @Author: Calvin Green <calvin>
 * @Date:   2021-05-08T11:48:33+02:00
 * @Email:  calvin@diversitude.com
 * @Last modified by:   calvin
 * @Last modified time: 2021-05-17T10:08:36+02:00
 * @Copyright: Diversitude 2021
 */



import store from '@/state/store'

export default [
  {
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/dashboards/default'),
  },
  /*{
    path: '/app/:id',
    name: 'app',
    props: true,
    meta: {
      authRequired: true,
    },
    component: () => import('./views/builder/app'),
    beforeEnter: (to, from, next) => {
      console.log(store)
      next();
    } 
  },*/
  {
    path: '/app',
    name: 'app',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/builder/app'),
    /*beforeResolve: (to, from, next) => {
      next({ path: '/' })
    } */
  },
  {
    path: '/form-builder',
    name: 'Form Builder',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/form-builder/form-builder-ui.vue'),
  },
  {
    path: '/testzone',
    name: 'testzone',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/testzone/testzone.vue'),
  },
  {
    path: '/plugin',
    name: 'plugin',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/render-plugin/render-plugin.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/account/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'default' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('./views/account/register'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'default' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: () => import('./views/account/forgot-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'default' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch('auth/logOut')
        } else {
          store.dispatch('authfack/logout')
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.push('/login')
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: 'default' } : { ...routeFrom })
      },
    },
  },

  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations

]
