import axios from 'axios'
const base = process.env.VUE_APP_API_BASEURL

export const state = {
    reports: [],
    selected_report: null,
}

export const actions = {
    getReports({commit}){
        return new Promise((resolve, reject)=>{
            axios.get( base + 'api/client/apps')
            .then((response)=>{
                console.log(response.data);
                commit('setApps', response.data);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },

    //eslint-disable-next-line no-empty-pattern
    saveNewReport({commit}, data){
        return new Promise((resolve)=>{
            /*axios.post( base + 'api/client/saveapp/', data)
            .then(()=>{
                resolve();
            })
            .catch(()=>{ reject(); })*/
            /*
            report :{
                id, name, description, components
            }
            */

            commit('addSavedReport', data);
            resolve();
        })
            
    },

    setSelectedReport({commit}, report){
        commit('setSelectedReport',report)
    },
    
}

export const mutations = {
    setReports(state, payload){
        state.reports = payload;
    },
    setSelectedReport(state, payload){
        state.selected_report = payload;
    },
    addSavedReport(state, payload){
        state.reports.push(payload);
    },
}