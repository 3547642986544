import axios from 'axios'
const base = process.env.VUE_APP_API_BASEURL

export const state = {
    apps: [],
    selected_app_id: null,
    selected_app: null,
}

export const actions = {
    getApps({commit}){
        return new Promise((resolve, reject)=>{
            axios.get( base + 'api/client/apps')
            .then((response)=>{
                console.log(response.data);
                commit('setApps', response.data);
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    refreshApps({commit}){
        commit();
    },

    //eslint-disable-next-line no-empty-pattern
    saveNewApp({}, data){
        return new Promise((resolve, reject)=>{
            axios.post( base + 'api/client/saveapp/', data)
            .then(()=>{
                resolve();
            })
            .catch(()=>{ reject(); })
        })
            
    },

    setSelectedID({commit}, id){
        commit('setSelectedAppID', id);
    },

    setSelectedApp({commit}, app){
        commit('setSelectedApp',app)
    }
}

export const mutations = {
    setApps(state, payload){
        state.apps = payload;
    },
    setSelectedAppID(state, payload){
        state.selected_app_id = payload;
    },
    setSelectedApp(state, payload){
        state.selected_app = payload;
    },
}