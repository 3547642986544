/**
 * @Author: Calvin Green <calvin>
 * @Date:   2021-05-25T10:37:30+02:00
 * @Email:  calvin@diversitude.com
 * @Last modified by:   calvin
 * @Last modified time: 2021-05-25T15:53:18+02:00
 * @Copyright: Diversitude 2021
 */

import axios from 'axios';
let spreadAxios = null;

class SpreadifyBackend {

    constructor(apiKey) {
        console.log("hit spreadify constructor");
        spreadAxios = axios.create({});
        spreadAxios.defaults.baseURL = "https://engine.spreadify.io/";
        spreadAxios.defaults.withCredentials = true;
        spreadAxios.defaults.headers.common = {
            'Access-Control-Allow-Origin': '*',
            'X-Requested-With': 'XMLHttpRequest'
        };
        spreadAxios.interceptors.request.use(function (config) {
            const AUTH_TOKEN = apiKey;
            config.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`

            return config;
        }, function (error) {
            // Do something with request error
            console.log(error);
        });
    }

    getViewConfig = (uid) => {
        return new Promise((resolve, reject) => {
            spreadAxios.get('spreadapi/app/viewconfig/' + uid)
            .then(response => {
                resolve(response.data);
            })
            .catch(e => {
              reject(e);
            });
        });
    }

    processViewData = (uid, data) => {
        return new Promise((resolve, reject) => {
            spreadAxios.post('spreadapi/app/processviewdata', {uid: uid, input_data: data})
            .then(response => {
                resolve(response.data);
            })
            .catch(e => {
              reject(e);
            });
        });
    }
}

let _spreadifyBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initSpreadifyBackend = (config) => {
    if (!_spreadifyBackend) {
        _spreadifyBackend = new SpreadifyBackend(config);
    }
    return _spreadifyBackend;
}

/**
 * Returns the spreadify backend
 */
const getSpreadifyBackend = () => {
    return _spreadifyBackend;
}

export { initSpreadifyBackend, getSpreadifyBackend }
